.tableHead {
  @apply bg-gray-100 px-2.5 py-[14px] text-left 2xl:px-3 2xl:py-4;
}

.tableBody {
  @apply border-b border-gray-200 px-2.5 py-[25px] 2xl:px-3 2xl:py-7;
}

.accessioningId {
  background-color: transparent;
  border: 0;
  border-radius: 20px;
  text-align: center;
  cursor: pointer;
}

.accessioningId:hover {
  background-color: #ebe9f2;
}


.accessioningId:active {
  background-color: #c9c7d0;
}


/* use with char &#8942; - three vertical dots */
.kebabButton {
  background-color: transparent;
  border: 0;
  border-radius: 20px;
  font-weight: bold;
  font-size: 1.2rem;
}

.kebabButton:hover {
  background-color: #ebe9f2;
}

.kebabButton:active {
  background-color: #c9c7d0;
}

.dataDropdown {
  padding: 1px;
  background-color: #fff;
  border: 1px black solid;
  text-transform: none;
  text-align: left;
  width: 95%;
}

.smallWordButton {
  display: inline-block;
  font-size: 75%;
  padding: 3px 5px;
  border-radius: 3px;
  cursor: pointer;
}

.smallWordButton:hover {
  color: #fff;
  background-color: #9880ee;
}

.smallWordButton:active {
  color: #fff;
  background-color: #6755a8;
}

.sortFilterHelpButton {
  display: inline-block;
  padding: .2rem .5rem;
  color: white;
  background-color: #0070b3;
  font-size: 90%;
  font-weight: bold;
  border-radius: 50%;
  cursor: pointer;
}

.sortFilterHelpButton:hover {
  color: #fff;
  background-color: #0060a2;
}

.sortFilterHelpButton:active {
  color: #fff;
  background-color: #003088;
}

.accessioningTH {
  border-bottom: 1px solid lightgray;
  padding: 2px 4px;
  text-align: center;
}

.accessioningTR {
  border-bottom: 1px solid lightgray;
}

.pagingButton {
  border-radius: 8px;
  border: 1px solid transparent;
  padding: 0.6em 1.2em;
  font-size: 1em;
  font-weight: 500;
  font-family: inherit;
  cursor: pointer;
  transition: border-color 0.25s;
}
.pagingButton:hover {
  border-color: #646cff;
  color: #fff;
  background-color: #cacaca;
  font-weight: bold;
}
.pagingButton:focus,
.pagingButton:focus-visible {
  outline: 4px auto -webkit-focus-ring-color;
}

.accessioning-modal {
  /*max-width: 1200px;*/
  width: 80%;
  min-width: 800px;
}

@media (max-width: 800px) {
  .accessioning-modal {
    min-width: 100%;
  }
}

@media (min-width: 1000px) {
  .accessioning-modal {
    max-width: 1200px;
  }
}

.accessioning-modal table {
  border-collapse: separate;
  border-spacing: 0;
  border: 2px solid #e5e8ee;
  border-radius: 6px;
}

.accessioning-modal th {
  text-align: center;
  background-color: #e2e7f1;
}

.accessioning-error-alert-modal {
  width: 400px;
  color: red;
  text-align: center;
}

.accessioning-success-alert-modal {
  width: 400px;
  text-align: center;
}

.accessioning-choice-modal {
  background-color: inherit;
}

.accessioning-choice-modal:hover {
  background-color: #f8e8f8;
  cursor: pointer;
}

.batch-case-link-button {
  margin-left: 1rem;
  background-color: transparent;
  border: 0;
  border-radius: 1rem;
  cursor: pointer;
  padding: 6px;
}

.batch-case-link-button:hover {
  background-color: rgba(255, 128, 128, 0.2);
}

.batch-case-link-button:active {
  background-color: rgba(255, 128, 128, 0.5);
}

.accessioning-type-clinical {
  font-size: 80%;
  color: #2e33ff;
  background-color: #e2e2f0;
  padding: 3px;
  border-radius: 2rem;
  border: 0;
}

.accessioning-type-research {
  font-size: 80%;
  color: #615b88;
  background-color: #ffeac5;
  padding: 3px;
  border-radius: 2rem;
  border: 0;
}

.accessioning-type-qc {
  font-size: 80%;
  color: #5b6144;
  background-color: #eeaaaa;
  padding: 3px;
  border-radius: 2rem;
  border: 0;
}

.accessioning-type-dna {
  font-size: 80%;
  color: #ffffff;
  background-color: #565687;
  padding: 3px;
  border-radius: 2rem;
  border: 0;
}


.ts-table-header-button {
  text-transform: uppercase;
  font-size: 80%;
  font-weight: normal;
  border: 0;
  background-color: transparent
}

.ts-table-header-button:hover {
  background-color: #ffddee;
  border: none;
  border-radius: 2rem;
}

.kebab-dropdown {
  border: none;
  border-radius: .5rem;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

.kebab-dropdown-item{
    display:block;
    width:100%;
    text-align:left;
    border: none;
    background-color: transparent;
    padding: 6px 10px;
}
.kebab-dropdown-item:hover{
    background-color: #f1f1f1;
}

.darken-on-hover:hover {
  filter: brightness(90%);
}

.table-link {
  overflow: hidden;
}

.search-result-row:hover {
  background-color: lightgray;
}

.table-link table thead tr:hover {
  background-color: inherit !important;
}
/* Covers the entire modal */
.loading-overlay {
  position: fixed; /* Covers entire screen, including modals */
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5); /* Dark semi-transparent overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Ensure it's above everything */
}

.loading-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.spinner-border {
  width: 3rem;
  height: 3rem;
  margin-bottom: 10px;
  color: #a6bed9 !important;
}